.projects-container {
  position: relative;
  transition: height 0.5s ease;
  .project-card {
    position: absolute;
    width: 100%;
    left: 0;
    transition:
      transform 0.5s ease,
      opacity 0.3s ease;
  }

  .project-card.hidden {
    opacity: 0;
    pointer-events: none;
  }
}
